import './index.css';
import { useMemo } from 'react';

const Countdown = () => {
  const timeLeft = useMemo(() => {
    const now = new Date();
    const newYear2025 = new Date('2025-01-01T00:00:00');
    // @ts-ignore
    const diffInMilliseconds = newYear2025 - now;

    if (diffInMilliseconds <= 0) {
      return { days: '0', hours: '0', minutes: '0' };
    }

    const minutes = Math.floor((diffInMilliseconds / (1000 * 60)) % 60);
    const hours = Math.floor((diffInMilliseconds / (1000 * 60 * 60)) % 24);
    const days = Math.floor(diffInMilliseconds / (1000 * 60 * 60 * 24));

    return {
      days: days.toString(),
      hours: hours.toString(),
      minutes: minutes.toString(),
    };
  }, []);

  return (
    <div className="custom-wrapper">
      <div className="custom-heading">
        <h3>Countdown Till</h3>
        <h1>2025</h1>
      </div>
      <div className="countdown">
        <div className="custom-box">
          <span className="custom-num" id="day-box">
            {timeLeft.days}
          </span>
          <span className="custom-text">Days</span>
        </div>
        <div className="custom-box">
          <span className="custom-num" id="hr-box">
            {timeLeft.hours}
          </span>
          <span className="custom-text">Hours</span>
        </div>
        <div className="custom-box">
          <span className="custom-num" id="min-box">
            {timeLeft.minutes}
          </span>
          <span className="custom-text">Minutes</span>
        </div>
      </div>
    </div>
  );
};

export default Countdown;
