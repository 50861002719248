const Header = () => {
  const isLoggedIn = true;

  return (
    <div className="flex flex-row justify-between items-center px-8 py-5 border-b-2 fixed bg-white w-full z-50">
      <div>logo</div>
      <div>
        {isLoggedIn ? (
          <div className="flex flex-row gap-3">
            <div className="border-2 hover:bg-red-400  hover:text-white border-red-400 rounded-lg px-3 py-1 cursor-pointer">
              Login
            </div>
            <div className="border-2 hover:bg-white hover:text-red-400 border-red-400 text-white text-center cursor-pointer bg-red-400 rounded-lg px-3 py-1">
              Register
            </div>
          </div>
        ) : (
          <div className="flex flex-row gap-5">
            <div>Generate</div>
            <div>Profile</div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Header;
