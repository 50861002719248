const Modal = ({ closeModal }: { closeModal: () => void }) => (
  <div className="bg-white absolute top-0 z-50 w-full h-full">
    <div className="border-2 border-b-gray-200 flex justify-end py-5 px-7">
      <button
        type="button"
        onClick={closeModal}
        className="border-2 hover:bg-red-400  hover:text-white border-red-400 rounded-lg px-3 py-1 cursor-pointer"
      >
        Close
      </button>
    </div>
  </div>
);

export default Modal;
