import './index.css';

const Santa = () => (
  <div className="flex justify-center items-center santa-container rounded-full">
    <div className="santa mt-20 ml-3">
      <div className="hand-l" />
      <div className="hand-r" />
      <div className="hat" />
      <div className="face">
        <div className="beard" />
        <div className="eyes" />
      </div>
      <div className="belt" />
      <div className="shoe" />
    </div>
  </div>
);

export default Santa;
