import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';

import { MainScreen, Login, Register, Profile } from './pages';

const App = () => (
  <div>
    <BrowserRouter>
      <Routes>
        <Route path="/main" element={<MainScreen />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="*" element={<Navigate to="/main" replace />} />
      </Routes>
    </BrowserRouter>
  </div>
);

export default App;
