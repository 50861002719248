import './index.css';

const Login = () => (
  <div className="main-container">
    <form className="form-container">
      <input
        type="text"
        placeholder="E-mail"
        className="custom-input border-4 border-red-400"
      />
      <input
        type="text"
        placeholder="Password"
        className="custom-input border-4 border-red-400"
      />
      <button
        className="text-xl border-2 hover:bg-white hover:text-red-400 border-red-400 text-white text-center cursor-pointer bg-red-400 rounded-lg px-8 py-2"
        type="submit"
      >
        Login
      </button>
    </form>
  </div>
);

export default Login;
