import './index.css';

const ChristmasCard = () => (
  <div className="christmas-card-container px-20">
    <div className="christmas-card">
      <img src="/assets/snowman-image.png" alt="nothing" />
      <div className="hidden main-text-container">
        <div className="christmas-card-text-container">
          <h2>Merry Christmas</h2>
          <p>
            May your Christmas be full of love, laughter and goodwill. Have a
            Merry Christmas .
          </p>
        </div>
      </div>
    </div>
  </div>
);

export default ChristmasCard;
