import { Header, Santa, ChristmasCard, Countdown } from '../../components';

const MainScreen = () => (
  <div>
    <Header />
    <div className="pt-[77px]">
      <div className="flex flex-col lg:flex-row gap-10 justify-between items-center px-20 py-10">
        <Santa />
        <div className="flex flex-col gap-10">
          <div className="flex flex-col gap-3">
            <div className="text-6xl text-gray-600">
              Making your life easier
            </div>
            <div className="text-xl text-gray-500">
              We provide easy solutions for spreading the holiday spirit.
            </div>
          </div>
          <Countdown />
        </div>
      </div>
      <div className="border-4 mx-6 my-10" />
      <div className="px-20 py-10">
        <div className="text-4xl text-gray-600 pb-12">
          How we made thousands smile
        </div>
        <div className="flex flex-row gap-6  justify-between overflow-scroll">
          <img
            src="/assets/car_thing.jpg"
            alt="placeholder"
            className="max-w-[350px] object-contain"
          />
          <img
            src="/assets/car_thing.jpg"
            alt="placeholder"
            className="max-w-[350px] object-contain"
          />
          <img
            src="/assets/car_thing.jpg"
            alt="placeholder"
            className="max-w-[350px] object-contain"
          />
          <img
            src="/assets/car_thing.jpg"
            alt="placeholder"
            className="max-w-[350px] object-contain"
          />
        </div>
      </div>
      <div className="border-4 mx-6 my-10" />
      <ChristmasCard />
      <div className="border-4 mx-6 my-10" />
      <div className="flex justify-center flex-col w-full px-20 pb-10">
        <div className="text-4xl text-gray-600 pb-10 justify-center flex">
          Start Cheering with us
        </div>
        <div className="justify-center flex">
          <div className="text-4xl max-w-[200px] border-2 hover:bg-white hover:text-red-400 border-red-400 text-white text-center cursor-pointer bg-red-400 rounded-lg px-5 py-2">
            Register
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default MainScreen;
