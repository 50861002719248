import { useState } from 'react';

import { Header, Modal } from '../../components';

const Profile = () => {
  const [isOpen, setIsOpen] = useState(false);
  const closeModal = () => {
    setIsOpen(false);
  };

  return (
    <div>
      <Header />
      <div className="pt-[77px]">
        <div className="flex flex-col">
          <div className="px-20 py-10 text-4xl">History</div>
          <div className="grid grid-cols-4 gap-7 px-20">
            <div className="flex justify-center items-center">
              <button
                type="button"
                onClick={() => setIsOpen(true)}
                className="flex flex-col justify-center w-fit items-center gap-7 bg-gray-100 px-10 py-16 cursor-pointer"
              >
                <img src="/assets/plus-icon.png" alt="no" className="max-w-9" />
                <div className="text-gray-600 text-lg">New Greeting</div>
              </button>
            </div>
            <div className="flex justify-center items-center">
              <div className="flex flex-col justify-center w-fit items-center gap-7 bg-gray-100 px-10 py-16 cursor-pointer">
                <img src="/assets/plus-icon.png" alt="no" className="max-w-9" />
                <div className="text-gray-600 text-lg">New Greeting</div>
              </div>
            </div>
            <div className="flex justify-center items-center">
              <div className="flex flex-col justify-center w-fit items-center gap-7 bg-gray-100 px-10 py-16 cursor-pointer">
                <img src="/assets/plus-icon.png" alt="no" className="max-w-9" />
                <div className="text-gray-600 text-lg">New Greeting</div>
              </div>
            </div>
            <div className="flex justify-center items-center">
              <div className="flex flex-col justify-center w-fit items-center gap-7 bg-gray-100 px-10 py-16 cursor-pointer">
                <img src="/assets/plus-icon.png" alt="no" className="max-w-9" />
                <div className="text-gray-600 text-lg">New Greeting</div>
              </div>
            </div>
            <div className="flex justify-center items-center">
              <div className="flex flex-col justify-center w-fit items-center gap-7 bg-gray-100 px-10 py-16 cursor-pointer">
                <img src="/assets/plus-icon.png" alt="no" className="max-w-9" />
                <div className="text-gray-600 text-lg">New Greeting</div>
              </div>
            </div>
            <div className="flex justify-center items-center">
              <div className="flex flex-col justify-center w-fit items-center gap-7 bg-gray-100 px-10 py-16 cursor-pointer">
                <img src="/assets/plus-icon.png" alt="no" className="max-w-9" />
                <div className="text-gray-600 text-lg">New Greeting</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isOpen && <Modal closeModal={closeModal} />}
    </div>
  );
};

export default Profile;
